(function () {
    'use strict';

    angular.module('informaApp')
        .component('userAccount', {
            templateUrl: 'components/account/account.ptl.html',
            controller: UserAccountController,
        });

    function UserAccountController($location, $window, OmnitureSvc, ConstantsSvc, AccountService, AuthenticationService, ToastrHelper) {
        this.close = close;

        this.user = null;
        this.updating = false;

        this.$onInit = () => {
            OmnitureSvc.trackMyAccount();

            this.user = AuthenticationService.getCurrentUser();

            loadCompanies.call(this, AccountService);

            if (!this.user) {
                AuthenticationService.onCurrentUserUpdated((user) => {
                    this.user = user;

                    updateUserCountry.call(this);
                });
            }
        };

        this.save = () => {
            const dataToUpdate = {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                contactMe: this.user.contactMe,
                company: this.user.company,
                countryId: this.user.country.id
            };

            if (!validateForm(dataToUpdate)) {
                ToastrHelper.showError('Please, fill in the required fields');
                return;
            }

            updateInfo.call(this, dataToUpdate, AuthenticationService, AccountService, ToastrHelper);
        };

        this.close = () => {
            $location.path(ConstantsSvc.urls.table);
        };

        this.changePassword = () => {
            AccountService.getResetPasswordLink()
                .then(link => $window.location.href = link);
        };
    }

    function updateInfo(dataToUpdate, AuthenticationService, AccountService, ToastrHelper) {
        this.updating = true;

        AccountService.update(dataToUpdate)
            .then(() => {
                this.updating = false;
                return AuthenticationService.loadUser();
            })
            .then(() => ToastrHelper.showSuccess('Account info has been updated.'))
            .catch(() => ToastrHelper.showError('Something went wrong while updating account info.'));
    }

    function validateForm(dataToUpdate) {
        return _.values(dataToUpdate).every(x => x !== '' && x !== null);
    }

    function loadCompanies(AccountService) {
        AccountService.getCountries().then(countries => {
            countries.unshift({name: 'Not selected', id: null});

            this.countries = countries;
            updateUserCountry.call(this);
        });
    }

    function updateUserCountry() {
        if (this.user && this.countries) {
            this.user.country = this.countries.find(x => x.id === this.user.countryId);
        }
    }
})();
